.two-line-truncate {
    display: -webkit-box;
    box-orient: vertical;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }